.heading {
  margin: 15px 0;
}

.heading strong {
  display: block;
  margin: 8px 0;
}

li {
  margin: 4px 0;
}
