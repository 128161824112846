.video-wrapper {
  display: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.5s linear;
}

.video-wrapper.active {
  display: block;
}
