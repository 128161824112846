@import url('https://use.typekit.net/avw5hmf.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,
    'museo-sans-rounded' 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: -apple-system, BlinkMacSystemFont,
    'museo-sans-rounded' 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

a,
div,
span,
button,
p,
input,
select,
li,
ul,
h1,
h2,
h3,
h4 {
  font-family: 'museo-sans-rounded';
}
